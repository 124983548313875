/* eslint-disable no-lonely-if */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-explicit-any */

import "react-activity/dist/library.css";

import {
  ExistentFileModal as DragDropExistentFileModal,
  ExistentFileModal as UploadExistentFileModal,
} from "@components/modals/ExistentFileModal";
import {
  IFile,
  IFolder,
  IFolderStructure,
  IIdentifiedFile,
} from "@models/files/File.type";
import { RadioNames, TRadioButton } from "@components/RadioButton";
import React, { useEffect, useRef, useState } from "react";
import {
  addToast,
  removeToast,
  updateToast,
} from "@redux/toasts/slices/toastsSlice";
import {
  applyFileOrderFilters,
  getExistentFilesValuesByFolder,
  getFiles,
  pasteFile,
  toggleSelectedFile,
  updateElementName,
  updateHierarchy,
} from "@redux/files/thunks/fileThunk";
import {
  createFolderFromDocumentsV2,
  getDocuments,
  showDocument,
  updateDocument,
} from "@redux/documents/thunks/documentsThunk";
import {
  enabledAllFolders,
  removeChildren,
  renameElement,
  setElementToTag,
  setFilters,
  setShowDragDropExistentFileModal,
} from "@redux/files/slices/fileSlice";
import { getFolderTrash, restoreFile } from "@redux/trash/thunks/trashThunk";
import {
  isAlreadyASection,
  isSectionCantDelete,
} from "../../app/helpers/warningMessages";
import { useLocation, useNavigate } from "react-router-dom";

import ActionModal from "@components/ActionModal";
import ActionsColumn from "@components/ActionsColumn";
import AddFolderModal from "@components/AddFolderModal";
import AdminHeader from "@components/AdminHeader";
import Button from "@components/Button";
import Checkbox from "@components/Checkbox";
import Chip from "@components/Chip";
// import ContextMenu from "@components/ContextMenu";
import { DeleteFileModal } from "../../components/modals/DeleteFileModal";
import { DeleteFilePermanentlyModal } from "@components/modals/DeleteFilePermanentlyModal";
import { EToastTypes, IToast } from "@models/toast/Toast.type";
import FileName from "@components/FileName";
import FilesModal from "@components/FilesModal";
import { GenericModal } from "@components/Modal";
import Grid from "@components/Grid";
import { IAction } from "@models/Action.type";
import { IRow } from "@models/Table.type";
import { MAX_FILE_SIZE } from "../../utils/constants";
import MoveToSection from "./DocumentsSection";
import { RenameModal } from "../../components/modals/RenameModal";
import { RestoreModal } from "@components/modals/RestoreModal";
import SimpleSearch from "@components/Search/SimpleSearch";
import { Spinner } from "react-activity";
import { TIcon } from "@components/MaterialIcon";
import Table from "@components/Table";
import { TagModal } from "@components/modals/TagModal";
import TreeView from "@components/TreeView";
import { colors } from "@theme/colors";
import { createToast } from "@helpers/createToast";
import emptyState from "../../assets/no-results.svg";
import { formatCapitalizeFirstLetter } from "@helpers/formatCapitalizeFirstLetter";
import { formatDate } from "@helpers/formatDate";
import { isEqual } from "lodash";
import { setCurrentResource } from "@redux/documents/documentsSlice";
import useAppDispatch from "@hooks/useAppDispatch";
import useAppSelector from "@hooks/useAppSelector";
import useBeforeUnload from "@hooks/utils/useBeforeUnload";
import { useContextMenu } from "@hooks/useContextMenu";
import { useDocumentUploadingStore } from "../../stores/useDocumentUploading";
import useDocumentsContext from "@hooks/useDocumentsContext";
import { useRenameElementActive } from "@hooks/useRenameElementActive";
import useToastContext from "@hooks/useToastContext";
import useWindowSize from "@hooks/useWindowSize";
import { useAddSectionDocument } from "@redux/sections/api";
import { TagsPopUp } from "@components/TagsPopUp";
import { useDownloadOriginalFiles } from "@hooks/useDownloadOriginalFiles";
import generateThumbnail from "@utils/generateThumbnail";
import {
  useGetDocumentSize,
  useGetDocumentRecursiveDownload,
} from "@redux/documents/api";

interface IFileManagementProps {}

const tagIcon: TIcon = "tag";
const addIcon: TIcon = "add";
const deleteIcon: TIcon = "delete";
const restoreIcon: TIcon = "restore";
const historyIcon: TIcon = "history";
const autoDeleteIcon: TIcon = "auto_delete";
const fileDownloadIcon: TIcon = "file_download";
const visibilityOffIcon: TIcon = "visibility_off";
const driveFileRenameIcon: TIcon = "drive_file_rename_outline";
const moveToIcon: TIcon = "drive_file_move";

const baseSrcUrl =
  process.env.NODE_ENV === "production" ? process.env.REACT_APP_BASE_URL : "";

const MOVE_TO_TOAST_TEXTS = {
  warning: "Moving element...",
  success: "Element moved successfully!",
  danger: "An error has occurred, please try again.",
};

const FileManagement: React.FC<IFileManagementProps> = () => {
  const [addSectionDocument] = useAddSectionDocument();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { responsive } = useWindowSize();
  const contextMenu = useContextMenu();
  const { downloadSingleFile, downloadFolder } = useDownloadOriginalFiles();
  const {
    // setPosition,
    file: fileCopied,
    // setFile,
    // isVisible,
    // setIsVisible,
  } = contextMenu;
  const uploadButtonRef = useRef<HTMLInputElement | null>(null);
  const renameElementActive = useRenameElementActive();
  const { setElement: setRenameElementId, elementToRename } =
    renameElementActive;
  const { folderTrashFiles } = useAppSelector((state) => state.trash);
  const { data: user } = useAppSelector((state) => state.user);
  const {
    file: {
      pending,
      pendingPost,
      data,
      folderFilters,
      selectedFiles,
      dropElementId,
      orderFilter,
      orderFilterName,
      downloading,
      showDragDropExistentFileModal,
      existentFileModalData,
    },
    toggle: { views },
  } = useAppSelector((state) => state);

  const {
    documents: {
      current: currentResource,
      pending: pendingDocument,
      pendingUpdating,
    },
  } = useAppSelector((state) => state);

  const documentsData = data.children.filter((document) => {
    const hasFolders = folderFilters.includes("folders");
    const hasVaults = folderFilters.includes("vaults");

    if (hasFolders && !hasVaults) {
      return document.type === null && document.vaults.length === 0;
    }

    if (hasVaults && !hasFolders) {
      return document.type === null && document.vaults.length > 0;
    }

    if (hasFolders && hasVaults) {
      return document.type === null || document.vaults.length > 0;
    }

    return true;
  });

  const [selectedFile, setSelectedFile] = useState<IFile | IFolder>();
  const [showTrash, setShowTrash] = useState<boolean | null>(false);
  const [headerActionList, setHeaderActionList] = useState<any>([]);
  const [eliminatedElements, setEliminatedElements] = useState<any[]>([]);
  const [modalDeleteVisible, setModalDeleteVisible] = useState<boolean>(false);
  const [isOpenMoveToModal, setIsOpenMoveToModal] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isModalAddFolderOpen, setIsModalAddFolderOpen] = useState(false);
  const [restoreModalVisible, setRestoreModalVisible] =
    useState<boolean>(false);
  const [modalDeletePermanentlyVisible, setModalDeletePermanentlyVisible] =
    useState<boolean>(false);
  const [showFilesModal, setShowFilesModal] = useState<boolean>(false);
  const [showRenameModal, setShowRenameModal] = useState<boolean>(false);
  const [folderId, setFolderId] = useState(location.pathname.split("/")[3]);
  const [showTagsModal, setShowTagsModal] = useState<boolean>(false);
  const [showExistentFileModal, setShowExistentFileModal] =
    useState<boolean>(false);
  const [indexCurrentExistentFile, setIndexCurrentExistentFile] =
    useState<number>(0);
  const [existentFiles, setExistentFiles] = useState<Array<IIdentifiedFile>>(
    [],
  );
  const [uploadExistentFiles, setUploadExistentFiles] = useState<
    Array<IIdentifiedFile>
  >([]);
  const [replaceNameFiles, setReplaceNameFiles] = useState<Array<string>>([]);
  const [isTreeViewOpen, setIsTreeViewOpen] = useState(false);
  const [isPreparingFiles, setIsPreparingFiles] = useState(false);
  const {
    elementToMove,
    setElementToMove,
    restartDocuments,
    searchDocument,
    setSearchDocument,
    filesToUpload,
    setFilesToUpload,
    foldersToUpload,
    setFoldersToUpload,
    uploadFolders,
    setFilesToUploadOnFolders,
    filesToUploadOnFolder,
    uploadFiles,
  } = useDocumentsContext();
  const id = useRef<number>(0);
  const targetFilesCopy = useRef<File[]>();
  const { showToast } = useToastContext();

  const { isUploading } = useDocumentUploadingStore((state) => state);
  const [getDocumentSize] = useGetDocumentSize();
  const [getDocumentRecursiveDownload] = useGetDocumentRecursiveDownload();
  const [estimatedTime, setEstimatedTime] = useState<number | null>(null); // Tiempo en segundos
  const [downloadAllFiles, setDownloadAllFiles] = useState<boolean | null>(
    null,
  );
  // const isDownloading = downloadAllFiles !== null && !downloadAllFiles;
  const [displayTime, setDisplayTime] = useState<string | null>(null);
  let timer: NodeJS.Timeout;

  useBeforeUnload(() => {
    return isUploading;
  });

  useEffect(() => {
    setFolderId(location.pathname.split("/")[3]);
    if (location.pathname.split("/")[3]) {
      dispatch(getFiles(parseInt(location.pathname.split("/")[3], 10)));
    } else {
      dispatch(getFiles(null));
    }
    setShowTrash(null);
    setEliminatedElements([]);
  }, [dispatch, location]);

  useEffect(() => {
    if (showTrash) {
      setHeaderActionList([
        {
          id: "hide_deleted_elements",
          icon: visibilityOffIcon,
          text: "Hide Deleted Elements",
          onClick: () => {
            setShowTrash(false);
            setEliminatedElements([]);
          },
        },
      ]);
      setEliminatedElements(folderTrashFiles);
    } else {
      setHeaderActionList([
        {
          id: "show_deleted_elements",
          icon: autoDeleteIcon,
          text: "Show Deleted Elements",
          onClick: (folderToDeleteId: string) => {
            dispatch(getFolderTrash(folderToDeleteId)).then(() => {
              setShowTrash(true);
            });
          },
        },
      ]);
      setEliminatedElements([]);
    }
  }, [showTrash]);

  useEffect(() => {
    if (showTrash) setEliminatedElements(folderTrashFiles);
  }, [folderTrashFiles]);

  useEffect(() => {
    if (pendingUpdating)
      showToast("warning", dispatch, "", 400, MOVE_TO_TOAST_TEXTS.warning);
  }, [pendingUpdating]);

  const handleDownloadFolder = async (fileId: number) => {
    const validatingToast: IToast = {
      id: "validating_folder",
      text: `<div class="d-flex">
              <div class="loader"></div>
              <span> Checking folder contents...</span>
            </div>`,
      type: EToastTypes.WARNING,
      withClose: true,
      autohide: false,
    };
    dispatch(addToast(validatingToast));

    const limitSize = 2 * 1073741824;
    const folderSize = await getDocumentSize({ id: fileId }).unwrap();
    dispatch(removeToast("validating_folder"));
    if (folderSize.size > limitSize) {
      const limitSizeToast: IToast = {
        id: "limitSizeToast",
        text: "Your selected folder exceed the 2 gb limit.",
        type: EToastTypes.DANGER,
      };
      dispatch(addToast(limitSizeToast));
    } else {
      const downloadToast: IToast = {
        id: "download_folder",
        text: `<div class="d-flex">
                <div class="loader"></div>
                <span> Preparing for download. Please do not leave this page.</span>
              </div>`,
        type: EToastTypes.WARNING,
        withClose: true,
        autohide: false,
      };
      dispatch(addToast(downloadToast));
      const documents = await getDocumentRecursiveDownload({
        id: fileId,
      }).unwrap();
      downloadFolder(
        documents,
        (time: any) => {
          setEstimatedTime(time); // Establecer tiempo estimado total
        },
        setDownloadAllFiles,
        folderSize.size,
      );
    }
  };

  useEffect(() => {
    if (downloadAllFiles) {
      dispatch(
        addToast({
          id: "download_folder_success",
          type: EToastTypes.PRIMARY,
          text: `Download completed successfully!`,
          withClose: true,
          autohide: true,
        }),
      );
      dispatch(removeToast("download_folder"));
      setDownloadAllFiles(null);
    } else {
      dispatch(
        updateToast({
          id: "download_folder",
          type: EToastTypes.WARNING,
          text: `<div class="d-flex">
                <div class="loader"></div>
                <span> ${estimatedTime && estimatedTime > 0 ? `Preparing for download. Estimated time ${displayTime}. Please do not leave this page.` : "Finishing download. Please do not leave this page."}</span>
              </div>`,
          withClose: false,
          autohide: false,
        }),
      );
    }
  }, [displayTime, downloadAllFiles]);

  useEffect(() => {
    if (estimatedTime !== null) {
      clearInterval(timer); // Limpia temporizadores previos
      timer = setInterval(() => {
        setEstimatedTime((prev) => {
          if (prev === null || prev <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prev - 1; // Reduce un segundo
        });
      }, 1000);
    }

    return () => clearInterval(timer); // Limpieza al desmontar
  }, [estimatedTime]);

  // Actualizar el tiempo mostrado
  useEffect(() => {
    if (estimatedTime !== null) {
      const minutes = Math.floor(estimatedTime / 60);
      const seconds = estimatedTime % 60;
      setDisplayTime(`${minutes}m ${seconds}s`);
      if (estimatedTime === 0) {
        setDisplayTime(null);
      }
    }
  }, [estimatedTime]);

  const actionList = [
    {
      id: "download",
      icon: fileDownloadIcon,
      text: "Download",
      onClick: async (file: IFile) => {
        const selectId = file?.id;
        const isFolder = file?.file_id === null;

        if (isFolder) {
          handleDownloadFolder(file.id);
        } else {
          downloadSingleFile(`${selectId}`);
        }
      },
    },
    {
      id: "moveTo",
      icon: moveToIcon,
      text: "Move to",
      onClick: (element: IFile) => {
        setIsOpenMoveToModal(true);
        setElementToMove({
          id: element.id,
          name: element.name,
          parent_id: element.parent_id,
          type: element.type,
          document_tags: element.document_tags,
        });
        dispatch(
          setCurrentResource({
            data: {
              id: data.id,
              name: data.name,
              parent_id: data.parent_id,
            },
          }),
        );
      },
      hidden: !responsive.md || !user?.permissions.Document?.includes("write"),
    },
    {
      id: "delete",
      icon: deleteIcon,
      text: "Delete",
      onClick: (element: IFolder | IFile) => {
        if (element.section) {
          isSectionCantDelete(dispatch);
        } else {
          setSelectedFile(element);
          setModalDeleteVisible(true);
        }
      },
      hidden: !user?.permissions.Document?.includes("write"),
    },
    {
      id: "rename",
      icon: driveFileRenameIcon,
      text: "Rename",
      onClick: (file: IFile) => {
        setRenameElementId(file);
        if (!responsive.md) {
          setShowRenameModal(true);
        }
      },
      hidden: !user?.permissions.Document?.includes("write"),
    },
    {
      id: "tags",
      icon: tagIcon,
      text: "Tags",
      onClick: (file: IFile) => {
        setShowTagsModal(true);
        dispatch(setElementToTag(file));
      },
      hidden: !user?.permissions.Document?.includes("write"),
    },
    {
      id: "history",
      icon: historyIcon,
      text: "History",
      onClick: (file: IFile) => {
        navigate(`/admin/file-management/history/${file.id}`, {
          replace: true,
        });
        localStorage.setItem("current_file_history", JSON.stringify(file));
      },
    },
    {
      id: "addSection",
      icon: addIcon,
      text: "Add as Section",
      onClick: (folder: IFolder) =>
        addSectionDocument({ sectionableId: folder.id })
          .unwrap()
          .then(() => {
            const text = `<strong>New Section added</strong><br>"${folder.name}" has been added as section successfully.<br/>
      <a style="color: white" href="${process.env.REACT_APP_BACK_BASE_URL}admin/new-section" target="_blank">Find it here</a>`;
            createToast(text, "success", dispatch);
          })
          .catch(() => {
            isAlreadyASection(folder, dispatch);
          }),
      hidden: !user?.permissions.Document?.includes("write"),
    },
    {
      id: "addVault",
      icon: addIcon,
      text: "Vault",
      onClick: () => {},
      goToMenu: "addVault",
      hidden: !user?.permissions.Vault?.includes("write"),
    },
  ];

  const trashActionList: IAction[] | undefined = [
    {
      id: "restore",
      icon: restoreIcon,
      text: "Restore",
      onClick: (file: IFile) => {
        setSelectedFile(file);
        localStorage.setItem(
          "parent_id_from_trash",
          parseInt(location.pathname.split("/")[3], 10).toString(),
        );
        setRestoreModalVisible(true);
      },
    },
    {
      id: "delete_permanently",
      icon: deleteIcon,
      text: "Delete",
      onClick: (file: IFile) => {
        setSelectedFile(file);
        setModalDeletePermanentlyVisible(true);
      },
    },
  ];

  const columns = [
    // SELECTION - Switch with next line when adding selection
    {
      header: "",
      id: "checkbox",
      colspan: { xs: "1" },
      field: "",
      body: (file: IRow) => (
        <Checkbox
          className={file.deleted_at && "d-none"}
          item={file as IFile}
          onChange={() => dispatch(toggleSelectedFile(file as IFile))}
          checked={selectedFiles.some((sFile: any) =>
            isEqual(sFile.id, file.id),
          )}
          showName={false}
        />
      ),
      fixedWidth: { xs: "42px" },
    },
    {
      header: "Name",
      id: "name",
      colspan: { xs: "3" },
      field: "name",
      body: (file: IRow) => {
        return (
          // <FileName name={file.type ? `${file.name}.${file.type}` : file.name} type={file.type} />
          <FileName
            name={file.name}
            type={file.type}
            deleted_at={file.deleted_at}
            rename={isEqual(file.id, elementToRename?.id)}
          />
        );
      },
    },
    {
      header: "Date",
      id: "created_at",
      colspan: { xs: "1" },
      field: "created_at",
      parsedString: ({ created_at }: IRow) => formatDate(created_at),
      fixedWidth: { xs: "100px", sm: "unset" },
    },
    {
      header: "Type",
      id: "type",
      colspan: { xs: "1" },
      field: "type",
      parsedString: ({ type }: IRow) => (type ? `.${type}` : "folder"),
      collapsable: true,
    },
    {
      header: "Tags",
      id: "tags",
      colspan: { xs: "1" },
      field: "",
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      body: (file: IRow) => {
        const { tags } = file;
        return tags.length > 0 ? (
          <TagsPopUp tags={tags} />
        ) : (
          <span className="o-ft-sm-400 o-cl-grey-200">-</span>
        );
      },
      collapsable: true,
    },
    {
      header: "Actions",
      id: "actions",
      colspan: { xs: "1" },
      field: "",
      body: (file: IRow) => (
        <ActionsColumn
          {...{ file }}
          actionList={file.deleted_at ? trashActionList : actionList}
          dotsShowLength={3}
          context="file-management"
        />
      ),
      hiddenMobileTitle: true,
      fixedWidth: { xs: "40px", lg: "160px" },
    },
  ];

  const prepareFiles = async (files: FileList): Promise<IIdentifiedFile[]> => {
    setIsPreparingFiles(true);
    const filesArray = await Promise.all(
      Array.from(files).map(async (file: File): Promise<IIdentifiedFile> => {
        // Generar thumbnail para el archivo
        const thumbnailBlob = await generateThumbnail({ file });
        return {
          // eslint-disable-next-line no-plusplus
          id: ++id.current,
          file,
          status: file.size / 1000 <= MAX_FILE_SIZE,
          thumbnailBlob,
        };
      }),
    );
    setIsPreparingFiles(false);
    return filesArray;
  };
  const handleFileLoad = async (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement;

    const fileArray = target.files ? await prepareFiles(target.files) : [];

    if (fileArray.some((file) => file.file.size === 0)) {
      setShowFilesModal(false);
      createToast(
        "Folders not supported. Please choose an individual file.",
        "danger",
        dispatch,
      );
    } else {
      setFilesToUpload([...filesToUpload, ...fileArray]);
      setShowFilesModal(true);
    }
  };

  const handleUploadFolder = async (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    const folderStructure: IFolderStructure[] = [];
    setIsPreparingFiles(true);
    if (target.files) {
      const targetFilesArray = Array.from(target.files);
      targetFilesCopy.current = targetFilesArray;
      const filePromises = Array.from(target.files).map(async (file: File) => {
        const parts = file.webkitRelativePath.split("/");
        let currentLevel: IFolderStructure[] = folderStructure;
        let currentFolder: IFolderStructure | undefined;

        const partPromises = parts.map(async (part, index) => {
          if (index === parts.length - 1) {
            // Es un archivo
            const thumbnailBlob = await generateThumbnail({ file });
            const fileToSend: IIdentifiedFile = {
              // eslint-disable-next-line no-plusplus
              id: ++id.current,
              file,
              status: file.size / 1000 <= MAX_FILE_SIZE,
              thumbnailBlob,
            };
            currentFolder?.files.push(fileToSend);
            if (currentFolder) currentFolder.size += file.size;
          } else {
            currentFolder = currentLevel.find((item) => item.name === part);
            if (!currentFolder) {
              // eslint-disable-next-line no-plusplus
              const newFolderId = ++id.current;
              currentFolder = {
                name: part,
                files: [],
                folders: [],
                id: newFolderId,
                size: 0,
                status: true,
              };
              currentLevel.push(currentFolder);
            }
            currentLevel = currentFolder?.folders;
          }
        });
        // Esperar a que todas las operaciones internas se completen
        await Promise.all(partPromises);
      });

      // Esperar a que todos los archivos sean procesados
      await Promise.all(filePromises);
      setFilesToUploadOnFolders(Array.from(targetFilesCopy.current).length);
      const folderSize = targetFilesCopy.current
        ? Array.from(targetFilesCopy.current).reduce(
            (acumulator, curre) => acumulator + curre.size,
            0,
          )
        : 0;

      folderStructure[0].size = folderSize;
    }

    setIsPreparingFiles(false);
    setFoldersToUpload([...foldersToUpload, ...folderStructure]);
    setShowFilesModal(true);
  };

  const handleSubmitFiles = (files: Array<IIdentifiedFile>) => {
    if (existentFiles.length) {
      setFilesToUpload(
        files.map((f) => {
          const file = f;
          if (replaceNameFiles.includes(file.file.name)) {
            file.action = RadioNames.Replace;
          }
          return file;
        }),
      );
    }

    if (files.length) {
      dispatch(
        addToast({
          id: "filesUploading",
          type: EToastTypes.WARNING,
          text: `<div class="d-flex">
            <div class="loader"></div>
            <span> Uploaded 0 of ${filesToUpload.length}  </span>
          </div>`,
          autohide: false,
          withClose: true,
        }),
      );
      uploadFiles({ parentId: Number(folderId) })
        .then(() => {
          const elementsCount = filesToUpload.length;
          const plural = elementsCount > 1;
          const elementsText = plural ? "elements" : "element";
          const verbText = plural ? "have" : "has";
          const text = `<strong>Elements uploaded</strong><br>${elementsCount} ${elementsText} ${verbText} been uploaded successfully!`;
          createToast(text, "success", dispatch);
        })
        .catch((err) => {
          console.error(err);
          dispatch(removeToast("filesUploading"));
          createToast(
            `We have an error uploading the folder`,
            "danger",
            dispatch,
          );
        })
        .finally(() => {
          if (location.pathname.split("/")[3]) {
            dispatch(getFiles(parseInt(location.pathname.split("/")[3], 10)));
          } else {
            dispatch(getFiles(null));
          }
        });
    }
  };

  const handleFileUpload = () => {
    const parentId = dropElementId ?? Number.parseInt(folderId, 10);
    const uploadNameFiles = filesToUpload.map((o) => o.file.name.toLowerCase());
    if (foldersToUpload.length > 0) {
      dispatch(
        addToast({
          id: "filesUploading",
          type: EToastTypes.WARNING,
          text: `Uploaded 0 of ${filesToUploadOnFolder}`,
          autohide: false,
          withClose: true,
        }),
      );
      uploadFolders({
        parentId: folderId === "" ? null : Number(folderId),
      })
        .then(() => {
          const elementsCount = foldersToUpload.length;
          const plural = elementsCount > 1;
          const elementsText = plural ? "elements" : "element";
          const verbText = plural ? "have" : "has";
          const text = `<strong>Elements uploaded</strong><br>${elementsCount} ${elementsText} ${verbText} been uploaded successfully!`;
          createToast(text, "success", dispatch);
          foldersToUpload.forEach(() => dispatch(enabledAllFolders()));
        })
        .catch((err) => {
          console.log(err);
          dispatch(removeToast("filesUploading"));
          createToast(
            `We have an error uploading the folder`,
            "danger",
            dispatch,
          );
        });
      setShowFilesModal(false);
      return;
    }
    dispatch(
      getExistentFilesValuesByFolder({ parentId, values: uploadNameFiles }),
    ).then((resp) => {
      const respExistentFilesValues = resp.payload;
      if (!respExistentFilesValues?.includes(true)) {
        setShowFilesModal(false);
        handleSubmitFiles(filesToUpload);
      } else {
        const existFiles = filesToUpload.filter(
          (_, idx) => respExistentFilesValues[idx],
        );
        setExistentFiles(existFiles);
        setUploadExistentFiles(filesToUpload);
        setShowFilesModal(false);
        if (existFiles.length) setShowExistentFileModal(true);
      }
    });
  };

  const onSubmitExistentFileModal = (opt: TRadioButton) => {
    if (isEqual(opt.name, RadioNames.Replace)) {
      const currentExistentFileName =
        existentFiles[indexCurrentExistentFile]?.file?.name;
      setReplaceNameFiles((prev) => [...prev, currentExistentFileName]);
    }
  };

  const onSubmitDragDropExistentFileModal = (opt: TRadioButton) => {
    switch (existentFileModalData.action) {
      case "rename":
        dispatch(renameElement(existentFileModalData.parameters));
        dispatch(
          updateElementName({
            file: existentFileModalData.parameters,
            action: opt.name,
          }),
        );
        break;
      case "drag_drop":
        dispatch(
          updateHierarchy({
            ...existentFileModalData.parameters,
            action: opt.name,
          }),
        );
        break;
      case "paste":
        dispatch(
          pasteFile({ ...existentFileModalData.parameters, action: opt.name }),
        );
        break;
      case "restore":
        dispatch(
          restoreFile({
            ...existentFileModalData.parameters,
            action: opt.name,
          }),
        );
        break;
      default:
        break;
    }
  };

  const onCancelExistentFileModal = () => {
    const currentExistentFileName =
      existentFiles[indexCurrentExistentFile]?.file?.name;
    const uploadFilesExistents = uploadExistentFiles.filter(
      (i) => i.file.name !== currentExistentFileName,
    );
    setUploadExistentFiles(uploadFilesExistents);
  };

  const orderingFunction = ({
    orderingId,
    label,
  }: {
    orderingId: string;
    label: string;
  }) => {
    dispatch(applyFileOrderFilters({ orderingId, parentId: data.id, label }));
  };

  const onArrowButtonClick = () => {
    dispatch(
      getDocuments({ parentId: currentResource?.parent_id || undefined }),
    );
    if (!currentResource?.parent_id) {
      dispatch(
        setCurrentResource({
          data: {
            parent_id: undefined,
            id: null,
            name: null,
          },
        }),
      );
    } else {
      dispatch(showDocument({ id: currentResource?.parent_id || undefined }));
    }
  };

  const moveElement = () => {
    if (elementToMove) {
      dispatch(
        updateDocument({
          id: elementToMove.id,
          params: {
            parent_id: currentResource?.id || null,
            action: "keep_both",
            name: elementToMove.name,
            document_tags:
              elementToMove.document_tags?.map((tag) => tag.id) || [],
          },
        }),
      ).then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          showToast(
            "success",
            dispatch,
            response.meta.arg.params.parent_id,
            5000,
            MOVE_TO_TOAST_TEXTS.success,
          );
          dispatch(removeChildren({ id: elementToMove.id }));
          return;
        }
        if (response.meta.requestStatus === "rejected") {
          showToast("danger", dispatch, "", 5000, MOVE_TO_TOAST_TEXTS.danger);
        }
      });
    }
    setIsConfirmModalOpen(false);
    restartDocuments();
  };

  const handleOnType = (value: string) => {
    setSearchDocument({
      query: value,
      only_folders: searchDocument !== "",
      parentId: currentResource?.id ? currentResource?.id : undefined,
    });
  };

  const handleOnClear = () => {
    setSearchDocument({
      query: "",
      only_folders: false,
      parentId: currentResource?.id ? currentResource?.id : undefined,
    });
  };

  useEffect(() => {
    if (!isOpenMoveToModal) return;
    if (folderId) {
      dispatch(getDocuments({ parentId: parseInt(folderId, 10) }));
    } else {
      dispatch(getDocuments({ parentId: undefined }));
    }
  }, [dispatch, isOpenMoveToModal, folderId]);

  const renderData = () =>
    views.admin.grid ? (
      <Grid
        context="file-management"
        trashActionList={trashActionList}
        actionList={actionList}
        handleFileLoad={handleFileLoad}
        checkboxes
        draggable
        // contextMenu={contextMenu}
        data={documentsData ? [...documentsData, ...eliminatedElements] : []}
        dynamicCardClassName={(args: IRow) => {
          const { id: cardId } = args;
          let className = "o-bg-transparent";
          if (responsive.md) {
            className = "c-grid__child:hover o-bg-grey-300:hover";
            if (
              isEqual(cardId, elementToRename?.id) ||
              isEqual(fileCopied?.id, cardId) ||
              selectedFiles.some((sFile) => isEqual(sFile.id, cardId))
            ) {
              className = "c-grid__child--selected o-bg-grey-300";
            }
          }
          return className;
        }}
        layout={isTreeViewOpen ? "c-grid--md-layout" : "c-grid--xl-layout"}
      />
    ) : (
      <Table
        draggable
        handleFileLoad={handleFileLoad}
        // contextMenu={contextMenu}
        data={documentsData ? [...documentsData, ...eliminatedElements] : []}
        columns={columns}
        extraClassName={`${responsive.md && "c-table--selectable mt-md-3"}`}
        dynamicRowClassName={(args: IRow) => {
          const { id: cardId } = args;
          let className = "o-bg-transparent";
          if (responsive.md) {
            className = "o-bg-grey-300:hover";
            if (
              isEqual(cardId, elementToRename?.id) ||
              isEqual(fileCopied?.id, cardId) ||
              selectedFiles.some((sFile) => isEqual(sFile.id, cardId))
            ) {
              className = "o-bg-grey-300 c-table__row--selected";
            }
          }
          return className;
        }}
      />
    );
  return (
    <>
      {/* {isVisible && (
        <ContextMenu
          folder_id={parseInt(folderId, 10)}
          data={fileCopied}
          contextMenu={contextMenu}
        />
      )} */}
      <FilesModal
        isLoading={pendingPost}
        isPreparingFiles={isPreparingFiles}
        show={showFilesModal || isPreparingFiles}
        setShow={setShowFilesModal}
        {...{
          handleFileLoad,
          handleFileUpload,
          handleUploadFolder,
        }}
      />
      <AddFolderModal
        folderId={currentResource?.id || null}
        isVisible={isModalAddFolderOpen}
        setIsVisible={setIsModalAddFolderOpen}
        onConfirmFolder={(folder: any) =>
          dispatch(
            createFolderFromDocumentsV2({
              params: {
                name: folder.name,
                action: "keep_both",
                parent_id: folder.parent_id,
                document_tags: null,
              },
            }),
          ).then(() => {
            const text = `<strong>Folder Created</strong><br>A new folder has been created`;
            createToast(text, "success", dispatch);
          })
        }
      />
      <GenericModal
        title={currentResource?.name || "File Management"}
        subtitle={
          <>
            Moving element: <b>{elementToMove?.name}</b>
          </>
        }
        onConfirmButton={() => {
          setIsOpenMoveToModal(false);
          setIsConfirmModalOpen(true);
        }}
        onCancelButton={() => {
          setIsOpenMoveToModal(false);
          restartDocuments();
        }}
        show={isOpenMoveToModal}
        confirmModalText="Move"
        withCancelButton={false}
        withArrowButton={currentResource?.parent_id !== undefined}
        onArrowButtonClick={() => onArrowButtonClick()}
        disabledConfirmButton={elementToMove?.parent_id === currentResource?.id}
        search={
          <SimpleSearch
            onType={handleOnType}
            onClear={handleOnClear}
            value={searchDocument}
            placeholder="Search folder"
            isFetching={false}
            borderStyle
          />
        }
        otherActions={[
          {
            action: (
              <Button
                onClick={() => setIsModalAddFolderOpen(true)}
                className="ms-md-1"
                icon={{
                  icon: "folder_open",
                  size: 18,
                  position: "right",
                }}
                value="Add Folder"
                disabled={pendingDocument}
              />
            ),
          },
        ]}
      >
        <MoveToSection />
      </GenericModal>
      <ActionModal
        isLoading={pendingUpdating}
        show={isConfirmModalOpen}
        title={`Move ${elementToMove?.type ? "file" : "folder"}`}
        subtitle={
          <>
            You are about to move &quot;{elementToMove?.name}&quot;{" "}
            {elementToMove?.type ? "file" : "folder"} to the following location:{" "}
            <span className="fw-bold">
              {`File Management${currentResource?.path || ""}`}
            </span>
          </>
        }
        buttonLeftText="Cancel"
        buttonRightText="Continue"
        onClickLeftButton={() => setIsConfirmModalOpen(false)}
        onClickRightButton={() => moveElement()}
      />
      <DeleteFileModal
        file={selectedFile}
        isVisible={modalDeleteVisible}
        setVisible={setModalDeleteVisible}
      />
      <RestoreModal
        file={selectedFile}
        isVisible={restoreModalVisible}
        setVisible={setRestoreModalVisible}
      />
      <DeleteFilePermanentlyModal
        file={selectedFile}
        isVisible={modalDeletePermanentlyVisible}
        setVisible={setModalDeletePermanentlyVisible}
      />
      <UploadExistentFileModal
        title={`Existent File: "${existentFiles[indexCurrentExistentFile]?.file?.name}"`}
        onExited={() => {
          if (indexCurrentExistentFile + 1 >= existentFiles.length) {
            setIndexCurrentExistentFile(0);
            handleSubmitFiles(uploadExistentFiles);
          }
        }}
        onFinish={() => {
          if (indexCurrentExistentFile + 1 < existentFiles.length) {
            setIndexCurrentExistentFile((prev) => prev + 1);
          } else {
            setShowExistentFileModal(false);
          }
        }}
        onSubmit={onSubmitExistentFileModal}
        onCancel={onCancelExistentFileModal}
        isVisible={showExistentFileModal}
      />
      <DragDropExistentFileModal
        title={existentFileModalData.title || ""}
        onFinish={() => {
          dispatch(setShowDragDropExistentFileModal(false));
        }}
        onSubmit={onSubmitDragDropExistentFileModal}
        onCancel={() => {}}
        isVisible={showDragDropExistentFileModal}
      />

      {!responsive.md && (
        <RenameModal
          isVisible={showRenameModal}
          setVisible={setShowRenameModal}
          renameElementActive={renameElementActive}
        />
      )}
      <TagModal isVisible={showTagsModal} setVisible={setShowTagsModal} />
      <div className="o-admin-screen-container w-100">
        <AdminHeader
          folder={data}
          actionList={headerActionList}
          ordering={{ orderFilter, orderFilterName, orderingFunction }}
          {...{
            handleFileLoad,
            handleUploadFolder,
            uploadButtonRef,
          }}
        />

        {responsive.md && (
          <div className="d-flex align-items-center gap-2 mt-3">
            {folderFilters.map((filter) => (
              <Chip
                key={filter}
                name={formatCapitalizeFirstLetter(filter)}
                onClose={() =>
                  dispatch(
                    setFilters(folderFilters.filter((f) => f !== filter)),
                  )
                }
                size="small"
              />
            ))}
            {folderFilters.length > 0 && (
              <Chip
                name="Clear All"
                onClose={() => dispatch(setFilters([]))}
                size="small"
                main
              />
            )}
          </div>
        )}

        <div className="row mt-4">
          {responsive.md && (
            <div className="col-3">
              <TreeView onOpen={setIsTreeViewOpen} loading={downloading} />
            </div>
          )}

          <div className={isTreeViewOpen ? "col-9" : "col-12"}>
            <div
            // onContextMenu={(e) => {
            //   e.preventDefault();
            //   setFile(undefined);
            //   setPosition({ x: e.pageX, y: e.pageY });
            //   setIsVisible(true);
            // }}
            >
              {pending || downloading ? (
                <div className="d-flex justify-content-center mt-5 flex-column align-items-center gap-4">
                  <Spinner
                    className="o-ft-xl o-ft-3xl@md"
                    color={colors.brandColorSecondary}
                  />
                  {downloading && (
                    <span>
                      Preparing element for download. Please wait before
                      navigation through folders.
                    </span>
                  )}
                </div>
              ) : (
                documentsData.length > 0 && renderData()
              )}
              {folderFilters.length > 0 &&
                documentsData.length === 0 &&
                !pending && (
                  <div className="d-flex flex-column justify-content-center align-items-center w-100">
                    <img
                      className="mt-4"
                      src={`${baseSrcUrl}${emptyState}`}
                      alt="empty state"
                      width={responsive.md ? 350 : 250}
                    />
                    <h3
                      className={`o-cl-dark-grey text-center mt-4 mb-3 ${
                        responsive.md ? "o-ft-2xl-600" : "o-ft-xl-600"
                      }`}
                    >
                      There are no results for filter selected
                    </h3>
                    <p
                      className={`v-my-stores__empty-state-description o-cl-dark-grey text-center ${
                        responsive.md ? "o-ft-lg-400" : "o-ft-sm-400"
                      }`}
                    >
                      It seems that the filter selected doesn&apos;t match with
                      any result, <br />
                      try adjusting or clearing your filters to display better
                      results.
                    </p>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FileManagement;
