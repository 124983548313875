/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  useSearchContacts,
  useSearchDocuments,
  useSearchProjects,
  useSearchStores,
} from "@redux/search/api";

import BrandSwitcher from "./BrandSwitcher";
import FavoriteLink from "@components/FavoriteLink";
import { IFilter } from "./Search/shared/Results";
import LogOut from "./LogOut";
import MaterialIcon from "./MaterialIcon";
import ProfileSettingsModal from "@components/ProfileSettingsModal";
import { RootState } from "@redux/store";
import Search from "./Search";
import debounce from "lodash/debounce";
import { images } from "@theme/images";
import { setResults } from "@redux/search/slice";
import { setSideBar } from "@slices/toggleSlice";
import useAppDispatch from "@hooks/useAppDispatch";
import useAppSelector from "@hooks/useAppSelector";
import { useGetBrands } from "@redux/brands/api";
import useHandleClickOutside from "@hooks/useHandleClickOutside";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useWindowSize from "@hooks/useWindowSize";

const filters: IFilter[] = [
  { id: 1, name: "documents", icon: "article" },
  { id: 2, name: "contacts", icon: "call" },
  { id: 3, name: "stores", icon: "storefront" },
  { id: 4, name: "projects", icon: "content_paste" },
];

function useSearch(searchValue: string, filter: IFilter | null) {
  const { data: user } = useAppSelector((state) => state.user);
  const { data: documents, isFetching: isFetchingDocuments } =
    useSearchDocuments(
      { search: searchValue },
      { skip: (filter && filter.name !== "documents") || !searchValue },
    );
  const { data: contacts, isFetching: isFetchingContacts } = useSearchContacts(
    { search: searchValue },
    { skip: (filter && filter.name !== "contacts") || !searchValue },
  );
  const { data: stores, isFetching: isFetchingStores } = useSearchStores(
    { search: searchValue, user },
    { skip: (filter && filter.name !== "stores") || !searchValue },
  );
  const { data: projects, isFetching: isFetchingProjects } = useSearchProjects(
    { search: searchValue, user },
    { skip: (filter && filter.name !== "projects") || !searchValue },
  );

  const isFetching: boolean =
    isFetchingDocuments ||
    isFetchingContacts ||
    isFetchingProjects ||
    isFetchingStores;

  const results: { [key: string]: { data: any; meta: any; links: any } } =
    useMemo(
      () => ({
        documents:
          !searchValue || (filter && filter.name !== "documents")
            ? { data: [] }
            : documents,
        contacts:
          !searchValue || (filter && filter.name !== "contacts")
            ? { data: [] }
            : contacts,
        stores:
          !searchValue || (filter && filter.name !== "stores")
            ? { data: [] }
            : stores,
        projects:
          !searchValue || (filter && filter.name !== "projects")
            ? { data: [] }
            : projects,
      }),
      [searchValue, filter, documents, contacts, stores, projects],
    );
  return { results, isFetching };
}

const Header = () => {
  const dispatch = useAppDispatch();
  const { responsive } = useWindowSize();
  const { sidebarShow } = useSelector((state: RootState) => state.toggle);
  const ref = useRef<null | HTMLDivElement>(null);
  const [searchShow, setSearchShow] = useState(false);

  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [filter, setFilter] = useState<IFilter | null>(null);
  const { results, isFetching } = useSearch(value, filter);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useHandleClickOutside(ref, () => setIsOpen(false));

  const onClickMenu = () => {
    setSearchShow(false);
    dispatch(setSideBar(!sidebarShow));
  };

  useEffect(() => {
    dispatch(setResults(results));
  }, [results, dispatch]);

  const { data: brands } = useGetBrands();

  const handleGetAllProcessTags = (
    search: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setValue(search.target.value);
  };

  const debouncedGetAllProcessTags = useCallback(
    debounce(handleGetAllProcessTags, 600),
    [],
  );

  const handleOnClear = () => {
    setValue("");
    const inputElement = document?.getElementById(
      "search-input",
    ) as HTMLInputElement | null;
    if (inputElement) {
      inputElement.value = "";
    }
  };

  return (
    <header className="container-fluid c-header__container">
      {responsive.md ? (
        <div className="c-header row align-items-center d-flex">
          <div
            ref={ref}
            className="c-header__logo-container col-3 text-center d-flex justify-content-center"
          >
            <div
              className={`c-header__desktop-logo-container ${isOpen && "o-bg-grey-300"}  d-flex align-items-center justify-content-center `}
              onClick={() => {
                if (brands.length > 1) {
                  setIsOpen(!isOpen);
                } else {
                  navigate("/dashboard");
                }
              }}
              role="button"
              tabIndex={0}
              onKeyDown={(event) => {
                if (event.key === "Enter" || event.key === " ") {
                  setIsOpen(!isOpen);
                }
              }}
            >
              <img
                className="c-header__desktop-logo d-inline-block o-cursor-pointer"
                src={images.brandLogo}
                alt={`${process.env.REACT_APP_BRAND_NAME} logo`}
              />
              {brands && brands.length > 1 && (
                <BrandSwitcher isOpen={isOpen} setIsOpen={setIsOpen} />
              )}
            </div>
          </div>
          <div className="c-header__search-container col-6 col-md-4 position-relative">
            <div id="searchBarOnboarding" className="">
              <Search
                filters={filters}
                results={results}
                isFetching={isFetching}
                onChange={debouncedGetAllProcessTags}
                onSelectFilter={setFilter}
                value={value}
                searchResultsTitle="Search by:"
                onClear={handleOnClear}
                viewAllBtn
              />
            </div>
          </div>

          <div className="col-3 col-md-4 d-flex align-items-center justify-content-around flex-grow-1">
            <div className="col-4 col-sm-3 col-md-6">
              <FavoriteLink />
            </div>
            <div className="col-2 col-sm-6 col-md-2 d-flex justify-content-center">
              <ProfileSettingsModal />
            </div>
            <div
              className="col-6 col-sm-3 col-md-4"
              style={{
                display: "flex",
                alignItems: "baseline",
                justifyContent: "center",
              }}
            >
              <div
                style={{ marginRight: 16, cursor: "pointer" }}
                title="Help Resources"
                onClick={() => navigate("/help-resources")}
                role="button"
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter" || event.key === " ") {
                    navigate("/help-resources");
                  }
                }}
              >
                <MaterialIcon
                  icon="help_outline"
                  size={24}
                  color="o-cl-grey-200"
                />
              </div>
              <LogOut />
            </div>
          </div>
        </div>
      ) : (
        <div className="c-header">
          <div className="c-header__main-section row align-items-center">
            <div className="col-3 col-md-3">
              <div className="c-header__mobile-menu d-md-none o-cursor-pointer">
                <span id="menuOnboarding">
                  <MaterialIcon
                    onClick={onClickMenu}
                    icon="menu"
                    size={40}
                    color="o-cl-grey-200"
                  />
                </span>
              </div>
            </div>
            <div className="col-6 col-md-4 d-flex justify-content-center">
              <div
                className="c-header__mobile-logo text-center d-flex align-items-center o-bg-grey-300"
                ref={ref}
                onClick={() => setIsOpen(!isOpen)}
                role="button"
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter" || event.key === " ") {
                    setIsOpen(!isOpen);
                  }
                }}
              >
                <img
                  className="c-header__mobile-logo__img o-cursor-pointer"
                  src={images.brandLogo}
                  alt={`${process.env.REACT_APP_BRAND_NAME} logo`}
                />
                {brands && (
                  <BrandSwitcher isOpen={isOpen} setIsOpen={setIsOpen} />
                )}
              </div>
            </div>
            <div className="c-avatar col-3 col-md-4 d-flex align-items-center">
              <FavoriteLink />
              <div
                id="searchIconOnboarding"
                className="c-avatar__mobile-section c-avatar__mobile-search d-block d-md-none"
              >
                <MaterialIcon
                  onClick={() => {
                    dispatch(setSideBar(false));
                    setSearchShow(!searchShow);
                  }}
                  className="c-search__desactive-icon o-cursor-pointer"
                  icon="search"
                  size={24}
                  color="o-cl-grey-200"
                />
              </div>
            </div>
          </div>
          {searchShow && (
            <div className="my-3">
              <Search
                filters={filters}
                results={results}
                isFetching={isFetching}
                onSelectFilter={setFilter}
                value={value}
                searchResultsTitle="Search by:"
                onClear={() => setValue("")}
                viewAllBtn
              />
            </div>
          )}
        </div>
      )}
    </header>
  );
};

export default Header;
