import ActionModal, { EModalSizes } from "@components/ActionModal";
import useAppSelector from "@hooks/useAppSelector";
import { RadioSwitch } from "@components/RadioSwitch";
import { TRadioButton } from "@components/RadioButton";
import React, { useState } from "react";

interface IDeleteModalProps {
  isVisible: boolean;
  onFinish: () => void;
  onSubmit: (opt: TRadioButton) => void;
  onCancel: () => void;
  onExited?: () => void;
  title: string;
}

export const ExistentFileModal = ({
  isVisible,
  title,
  onFinish,
  onSubmit,
  onCancel,
  onExited,
}: IDeleteModalProps) => {
  const {
    file: { pendingDelete },
  } = useAppSelector((state) => state);
  const radioOptions: Array<TRadioButton> = [
    {
      id: 1,
      name: "replace",
      value: "Replace the element",
    },
    {
      id: 2,
      name: "keep_both",
      value: "Keep both",
    },
  ];
  const initialActiveIndex = 1;
  const [chosenOption, setChosenOption] = useState<TRadioButton>(
    radioOptions[initialActiveIndex],
  );
  const [currentIndex, setCurrentIndex] = useState<number>(initialActiveIndex);

  const handleChangeRadioSwitch = (opt: TRadioButton) => {
    setChosenOption(opt);
  };

  return (
    <ActionModal
      isLoading={pendingDelete}
      show={isVisible}
      customSize={EModalSizes.md}
      title={<span>{title}</span>}
      subtitle="There is already an element with the same name in this location. What do you want to do?:"
      buttonLeftText="Cancel"
      buttonRightText="Continue"
      onExited={onExited}
      onClickLeftButton={() => {
        onCancel();
        onFinish();
      }}
      onClickRightButton={() => {
        onSubmit(chosenOption);
        onFinish();
      }}
      renderContent={
        <RadioSwitch
          {...{ currentIndex, setCurrentIndex }}
          values={radioOptions}
          onChange={handleChangeRadioSwitch}
        />
      }
    />
  );
};
