/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from "yup";

import { Formik, Form as FormikForm } from "formik";
import {
  getExistentFilesValuesByFolder,
  updateElementName,
} from "@redux/files/thunks/fileThunk";
import {
  renameElement,
  setDragDropExistentFileData,
  setShowDragDropExistentFileModal,
} from "@redux/files/slices/fileSlice";

import ActionModal from "@components/ActionModal";
import Button from "@components/Button";
import { IFile } from "@models/files/File.type";
import React from "react";
import TextField from "@components/TextField";
import _ from "lodash";
import { formatExtension } from "@helpers/formatExtension";
import { renameResultElement } from "@redux/results/slices/adminResultSlice";
import useAppDispatch from "@hooks/useAppDispatch";
import useAppSelector from "@hooks/useAppSelector";
import { createToast } from "@helpers/createToast";

interface IRenameElementActive {
  setElement: (el: IFile | null) => {
    payload: IFile | null;
    type: string;
  };
  elementToRename: IFile | null;
}

interface IRenameModalProps {
  isVisible: boolean;
  setVisible: (value: boolean) => void;
  renameElementActive: IRenameElementActive; // hook useRenameElementActive ya instanciado
}

export const RenameModal = ({
  isVisible,
  setVisible,
  renameElementActive,
}: IRenameModalProps) => {
  const { data: resultsData } = useAppSelector((state) => state.adminResults);
  const { setElement: setRenameElementId, elementToRename } =
    renameElementActive;
  const dispatch = useAppDispatch();

  const renameSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });

  const closeRenameModal = () => {
    setRenameElementId(null);
    setVisible(false);
  };

  const validateFormHasEmptyFields = (values: any) => {
    let response = false;
    Object.entries(values).forEach(([prop]) => {
      if (_.isEmpty(values[prop].trim())) response = true;
    });
    return response;
  };

  const onSubmit = (values: any) => {
    const name: string | undefined = values?.name || elementToRename?.name;
    const element: any = { ...elementToRename, name };
    if (!_.isEqual(values?.name, elementToRename?.name)) {
      dispatch(
        getExistentFilesValuesByFolder({
          parentId: element.parent_id,
          values: [`${element.type ? `${name}.${element.type}` : `${name}`}`],
        }),
      ).then((resp) => {
        const respExistentFilesValues = resp.payload;
        if (!respExistentFilesValues?.includes(true)) {
          dispatch(renameElement(element));
          if (resultsData) dispatch(renameResultElement(element));
          dispatch(updateElementName({ file: element }));
        } else {
          if (!element.type) {
            createToast(
              `The name "${element.name}" already exists. Please choose another.`,
              "danger",
              dispatch,
            );
            return;
          }
          dispatch(
            setDragDropExistentFileData({
              title: "Rename File",
              action: "rename",
              parameters: element,
            }),
          );
          dispatch(setShowDragDropExistentFileModal(true));
        }
      });
    }
    closeRenameModal();
  };

  return (
    <ActionModal
      show={isVisible}
      title="Rename File"
      includesForm
      renderContent={
        <Formik
          initialValues={{ name: elementToRename?.name }}
          onSubmit={onSubmit}
          validationSchema={renameSchema}
        >
          {({ handleBlur, values, errors }) => (
            <FormikForm
              className="d-flex flex-column flex-grow-1"
              noValidate
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <TextField
                key="name"
                name="name"
                label="Name"
                type="text"
                handleBlur={handleBlur}
                placeholder="Rename file"
                error={errors.name}
                renderRight={
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {elementToRename?.type ? (
                      <span>{formatExtension(elementToRename?.type)}</span>
                    ) : null}
                  </>
                }
              />
              <div className="d-flex w-100 mt-4 align-items-center justify-content-between pt-1">
                <Button
                  className="w-100 mx-2 mx-md-4"
                  onClick={closeRenameModal}
                  size="medium-big"
                  value="Cancel"
                />
                <Button
                  className="w-100 mx-2 mx-md-4"
                  type="submit"
                  size="medium-big"
                  disabled={
                    validateFormHasEmptyFields(values) || !_.isEmpty(errors)
                  }
                  value="Save"
                />
              </div>
            </FormikForm>
          )}
        </Formik>
      }
    />
  );
};
