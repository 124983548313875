/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IFile, IFolder } from "@models/files/File.type";
import {
  pasteFileReducer,
  setCopiedFile,
  setCurrentHierarchy,
  setFileOrderFilters,
  setFilter,
  setQuery,
  toggleSelectedFiles,
} from "@redux/files/slices/fileSlice";

import FileController from "@controllers/FileController";
import { IRow } from "../../../app/types/Table.type";
import { TRadioButtonName } from "@components/RadioButton";
import { addTrash } from "@redux/trash/slices/trashSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createToast } from "@helpers/createToast";
import { errorRequest } from "@helpers/errorRequest";
import { filterRouteByOrdering } from "@helpers/filterRouteByOrdering";

import { formatNotificationNoty } from "@helpers/formatNotificationNoty";

declare global {
  interface Window {
    Noty: any;
  }
}

const fileController = new FileController();

export const getFiles = createAsyncThunk(
  "file/index",
  async (parentId: any, { rejectWithValue }) => {
    try {
      const response: any = await fileController.index(parentId);
      return response?.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getAllFiles = createAsyncThunk(
  "files/all",
  async (_, { rejectWithValue }) => {
    try {
      const response: any = await fileController.getAll();
      return response?.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getFoldersId = createAsyncThunk(
  "folder/index",
  async (arg: void, { rejectWithValue }) => {
    try {
      const response: any = await fileController.getFoldersId();
      return response?.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getLastEditedFiles = createAsyncThunk(
  "file/edited/index",
  async (args, { rejectWithValue }) => {
    try {
      const response: any = await fileController.getLastEditedFiles();
      return response?.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const showDocument = createAsyncThunk(
  "file/edited/index",
  async ({ id }: { id: string }) => {
    try {
      const response: any = await fileController.showDocument(id);
      return response?.data;
    } catch (err: any) {
      return err;
    }
  },
);

export const deleteFiles = createAsyncThunk(
  "files/delete",
  async (files: IFile[] | IRow[], { rejectWithValue, dispatch }) => {
    return Promise.all(
      files.map(async (file: IFile | IRow) => {
        try {
          await fileController.delete(file.id);
          dispatch(addTrash(file as IFile));
          return file;
        } catch (error: any) {
          return {
            name: "error",
            error: error.response.status,
            message: error.response.data.data.Message,
          };
        }
      }),
    ).then((response) => {
      let errors = [];
      errors = response.filter((res) => res.error);
      let deletedFiles = [];
      deletedFiles = response.filter((res) => !res.error);
      let text;
      const shortenDeletedFile =
        deletedFiles[0]?.name.length > 20
          ? deletedFiles[0]?.name.substring(0, 80).concat("...")
          : deletedFiles[0]?.name;
      if (deletedFiles.length) {
        if (deletedFiles.length > 1) {
          text = `<strong>Files Deleted</strong><br>${deletedFiles.length} files have been moved to trash.`;
        } else {
          text = `<strong>File Deleted</strong><br>File "${shortenDeletedFile}" moved to trash.`;
        }
        createToast(text, "success", dispatch);
      }
      if (errors.length) {
        let error = false;
        errors.forEach((err) => {
          switch (err.error) {
            case 409:
              createToast(err.message, "warning", dispatch);
              break;
            default:
              error = true;
              break;
          }
        });
        if (error) errorRequest(response, rejectWithValue, dispatch);
      }
      return deletedFiles;
    });
  },
);

export const postFolder = createAsyncThunk(
  "folder/post",
  async (folder: IFolder, { rejectWithValue, dispatch }) => {
    try {
      const response: any = await fileController.addFolder(folder);
      const text = `<strong>Folder Created</strong><br>A new folder has been created`;
      createToast(text, "success", dispatch);
      return response?.data?.data;
    } catch (err: any) {
      return errorRequest(err, rejectWithValue, dispatch);
    }
  },
);

export const toggleSelectedFile = createAsyncThunk(
  "file/post",
  // eslint-disable-next-line consistent-return
  async (file: IFile, { rejectWithValue, dispatch }) => {
    try {
      dispatch(toggleSelectedFiles(file));
    } catch (err: any) {
      return errorRequest(err, rejectWithValue, dispatch);
    }
  },
);

export const updateElementName = createAsyncThunk(
  "file/update-name",
  async (
    args: { file: IFile; action?: TRadioButtonName },
    { rejectWithValue, dispatch },
  ) => {
    const { file, action = "keep_both" } = args;
    const notification =
      action === "replace"
        ? {
            text: "File Replaced",
            description: `The file “${file.name}.${file.type}” has been replaced successfully.`,
          }
        : {
            text: "File Renamed",
            description: "The file has been renamed successfully.",
          };

    try {
      const response: any = await fileController.updateName(file, action);
      createToast(formatNotificationNoty(notification), "success", dispatch);
      if (action === "replace") await dispatch(getFiles(file.parent_id));
      return response?.data;
    } catch (err: any) {
      return errorRequest(err, rejectWithValue, dispatch);
    }
  },
);

export const trackInteractions = createAsyncThunk(
  "file/track",
  async (
    { data, type }: { data: number[]; type: "download" | "share" },
    { rejectWithValue },
  ) => {
    try {
      const response: any = await fileController.track(data, type);

      return response?.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const copyFile = createAsyncThunk(
  "file/paste",
  // eslint-disable-next-line consistent-return
  async (file: IFile, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setCopiedFile(file));
      const text = `<strong>File Copied</strong><br>File copied successfully.`;
      createToast(text, "success", dispatch);
    } catch (err: any) {
      return errorRequest(err, rejectWithValue, dispatch);
    }
  },
);

export const pasteFile = createAsyncThunk(
  "file/paste",
  async (
    args: {
      file: IFile;
      parent_id: number | null;
      section_id?: number;
      action?: TRadioButtonName;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { file, parent_id: parentId, action = "keep_both" } = args;
    try {
      const response: any = await fileController.pasteFile(
        file.id,
        parentId,
        action,
      );
      dispatch(pasteFileReducer(response.data));
      let text = `<strong>File Pasted</strong><br>File pasted successfully.`;
      if (action === "replace") {
        await dispatch(getFiles(parentId));
        text = `<strong>File Replaced</strong><br>The file <span class="o-ft-700">“${file.name}.${file.type}”</span> has been replaced successfully.`;
      }
      createToast(text, "success", dispatch);
    } catch (err: any) {
      return errorRequest(err, rejectWithValue, dispatch);
    }
  },
);

export const updateHierarchy = createAsyncThunk(
  "file/hierarchy",
  async (
    {
      parent,
      element,
      action = "keep_both",
    }: { parent: IFile; element: IFile | null; action?: TRadioButtonName },
    { rejectWithValue, dispatch },
  ) => {
    try {
      dispatch(setCurrentHierarchy(element));
      const response: any = await fileController.updateHierarchy(
        parent,
        element,
        action,
      );

      return response?.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const applyFileOrderFilters = createAsyncThunk(
  "file/filters/sort",
  async (
    args: {
      orderingId: number | string;
      parentId: number | string;
      label: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { orderingId, parentId } = args;
    try {
      dispatch(setFileOrderFilters({ ...args }));
      const query = filterRouteByOrdering(orderingId, "");
      const response = await fileController.filterBy(parentId, query);
      return response?.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

// AdminSearch
export const getFilesWithQuery = createAsyncThunk(
  "file/search",
  async (args: any, { rejectWithValue }) => {
    const { query, filter } = args;
    try {
      const response = await fileController.searchBy(query, filter);
      return { response: response?.data, requestQuery: query };
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateQuery = createAsyncThunk(
  "file/update-query",
  async (query: string, { dispatch }) => {
    dispatch(setQuery(query));
  },
);

export const updateFilter = createAsyncThunk(
  "file/update-filter",
  async (filter: string | undefined, { dispatch }) => {
    dispatch(setFilter(filter));
  },
);
// AdminSearch

export const getExistentFilesValuesByFolder = createAsyncThunk(
  "file/existent-files-values",
  async (
    { parentId, values }: { parentId: any; values: Array<string> },
    { rejectWithValue },
  ) => {
    try {
      const parent = parentId || null;
      const response: any = await fileController.checkRepeatedFiles(
        parent,
        values,
      );
      return response?.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);
// Versioning history thunks

export const getVersionsHistory = createAsyncThunk(
  "getVersionsHistory",
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      const response: any = await fileController.getVersionsHistory(id);
      return response?.data;
    } catch (err: any) {
      return errorRequest(err, rejectWithValue, dispatch);
    }
  },
);

export const restoreVersion = createAsyncThunk(
  "restoreVersion",
  async (
    { documentId, versionId }: { documentId: number; versionId: number },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response: any = await fileController.restoreVersion(
        documentId,
        versionId,
      );

      return response?.data;
    } catch (err: any) {
      return errorRequest(err, rejectWithValue, dispatch);
    }
  },
);
